var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"p-4"},[(_vm.searchClient)?_c('div',[_c('ais-instant-search',{attrs:{"index-name":"relations","search-client":_vm.searchClient}},[_c('div',{},[_c('ais-search-box',{attrs:{"show-loading-indicator":"","autofocus":""},scopedSlots:_vm._u([{key:"default",fn:function({ currentRefinement, isSearchStalled, refine }){return _c('div',{},[_c('input',{staticClass:"form-input block w-full rounded-none rounded-l-md pl-4 transition ease-in-out duration-150 sm:text-sm sm:leading-5",attrs:{"placeholder":"Rechercher dans les relations…","type":"search"},domProps:{"value":currentRefinement},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return refine($event.currentTarget.value)}}}),_c('span',{attrs:{"hidden":!isSearchStalled}},[_vm._v("Loading...")])])}}],null,false,2058671409)},[_c('div',{attrs:{"slot":"submit-icon"},slot:"submit-icon"},[_c('fa',{attrs:{"icon":['far', 'search']}})],1),_c('div',{attrs:{"slot":"reset-icon"},slot:"reset-icon"},[_c('fa',{attrs:{"icon":['far', 'times']}})],1)]),_c('div',{staticClass:"flex mt-4"},[_c('div',{staticClass:"flex-1 max-w-xl"},[_c('ais-hits',{staticClass:"bg-white shadow overflow-hidden sm:rounded-md",scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('router-link',{staticClass:"block border-gray-200 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out",class:index > 0 ? 'border-t' : '',attrs:{"to":{
                    name: 'relation-general',
                    params: { bid: item.bid, rid: item.objectID }
                  }}},[_c('div',{staticClass:"px-2 py-2 sm:px-4"},[(item.company)?_c('ais-highlight',{staticClass:"mr-1",attrs:{"hit":item,"attribute":"company"}}):_vm._e(),(item.givenName)?_c('ais-highlight',{staticClass:"mr-1",attrs:{"hit":item,"attribute":"givenName"}}):_vm._e(),_c('ais-highlight',{attrs:{"hit":item,"attribute":"familyName"}})],1)])]}}],null,false,3012336709)})],1),_c('div',{staticClass:"max-w-sm ml-4"},[_c('div',{staticClass:"p-2 bg-white rounded shadow"},[_c('h2',{staticClass:"font-medium"},[_vm._v("Archivés")]),_c('ais-toggle-refinement',{attrs:{"attribute":"archived","label":"Archivés","on":true,"off":false}}),_c('h2',{staticClass:"font-medium mt-2"},[_vm._v("Gestionnaire")]),_c('ais-refinement-list',{attrs:{"attribute":"manager"}}),_c('h2',{staticClass:"font-medium mt-2"},[_vm._v("Chargé")]),_c('ais-refinement-list',{attrs:{"attribute":"assistant"}}),_c('h2',{staticClass:"font-medium mt-2"},[_vm._v("Apporteur d'affaires")]),_c('ais-refinement-list',{attrs:{"attribute":"intermediary"}}),_c('h2',{staticClass:"font-medium mt-2"},[_vm._v("Naissance")]),_c('ais-range-input',{attrs:{"attribute":"birthDateTs"},scopedSlots:_vm._u([{key:"default",fn:function({ currentRefinement, range, canRefine, refine }){return _c('form',{},[_c('input',{attrs:{"type":"date","min":_vm.tsToIso(range.min),"max":_vm.tsToIso(range.max),"placeholder":_vm.tsToIso(range.min),"disabled":!canRefine},domProps:{"value":_vm.tsToIso(currentRefinement.min, range.min)},on:{"input":function($event){refine({
                        min: _vm.isoToTs($event.currentTarget.value),
                        max: currentRefinement.max
                      })}}}),_c('input',{attrs:{"type":"date","min":_vm.tsToIso(range.min),"max":_vm.tsToIso(range.max),"placeholder":_vm.tsToIso(range.max),"disabled":!canRefine},domProps:{"value":_vm.tsToIso(currentRefinement.max, range.max)},on:{"input":function($event){refine({
                        min: currentRefinement.min,
                        max: _vm.isoToTs($event.currentTarget.value)
                      })}}})])}}],null,false,2385816290)})],1)])])],1),_c('div',{staticClass:"mt-4 py-3 flex items-center justify-between border-t border-gray-200"},[_c('ais-pagination'),_c('ais-hits-per-page',{attrs:{"items":[
            { label: '10 par page', value: 10, default: true },
            { label: '20 par page', value: 20 }
          ]}})],1)])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }