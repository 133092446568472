<template>
  <main class="p-4">
    <div v-if="searchClient">
      <ais-instant-search index-name="relations" :search-client="searchClient">
        <div class="">
          <ais-search-box show-loading-indicator autofocus>
            <div slot-scope="{ currentRefinement, isSearchStalled, refine }">
              <input
                class="form-input block w-full rounded-none rounded-l-md pl-4 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="Rechercher dans les relations…"
                type="search"
                :value="currentRefinement"
                @keydown.enter="refine($event.currentTarget.value)"
              />
              <span :hidden="!isSearchStalled">Loading...</span>
            </div>
            <div slot="submit-icon">
              <fa :icon="['far', 'search']" />
            </div>
            <div slot="reset-icon">
              <fa :icon="['far', 'times']" />
            </div>
          </ais-search-box>
          <div class="flex mt-4">
            <div class="flex-1 max-w-xl">
              <ais-hits class="bg-white shadow overflow-hidden sm:rounded-md">
                <template slot="item" slot-scope="{ item, index }">
                  <router-link
                    class="block border-gray-200 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                    :class="index > 0 ? 'border-t' : ''"
                    :to="{
                      name: 'relation-general',
                      params: { bid: item.bid, rid: item.objectID }
                    }"
                  >
                    <div class="px-2 py-2 sm:px-4">
                      <ais-highlight
                        :hit="item"
                        attribute="company"
                        class="mr-1"
                        v-if="item.company"
                      />
                      <ais-highlight
                        :hit="item"
                        attribute="givenName"
                        class="mr-1"
                        v-if="item.givenName"
                      />
                      <ais-highlight :hit="item" attribute="familyName" />
                      <!-- <pre>{{item}}</pre> -->
                    </div>
                  </router-link>
                </template>
              </ais-hits>
            </div>
            <div class="max-w-sm ml-4">
              <div class="p-2 bg-white rounded shadow">
                <h2 class="font-medium">Archivés</h2>
                <ais-toggle-refinement
                  attribute="archived"
                  label="Archivés"
                  :on="true"
                  :off="false"
                />
                <h2 class="font-medium mt-2">Gestionnaire</h2>
                <ais-refinement-list attribute="manager" />
                <h2 class="font-medium mt-2">Chargé</h2>
                <ais-refinement-list attribute="assistant" />
                <h2 class="font-medium mt-2">Apporteur d'affaires</h2>
                <ais-refinement-list attribute="intermediary" />
                <h2 class="font-medium mt-2">Naissance</h2>
                <ais-range-input attribute="birthDateTs">
                  <form
                    slot-scope="{ currentRefinement, range, canRefine, refine }"
                  >
                    <input
                      type="date"
                      :min="tsToIso(range.min)"
                      :max="tsToIso(range.max)"
                      :placeholder="tsToIso(range.min)"
                      :disabled="!canRefine"
                      :value="tsToIso(currentRefinement.min, range.min)"
                      @input="
                        refine({
                          min: isoToTs($event.currentTarget.value),
                          max: currentRefinement.max
                        })
                      "
                    />
                    <input
                      type="date"
                      :min="tsToIso(range.min)"
                      :max="tsToIso(range.max)"
                      :placeholder="tsToIso(range.max)"
                      :disabled="!canRefine"
                      :value="tsToIso(currentRefinement.max, range.max)"
                      @input="
                        refine({
                          min: currentRefinement.min,
                          max: isoToTs($event.currentTarget.value)
                        })
                      "
                    />
                  </form>
                </ais-range-input>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-4 py-3 flex items-center justify-between border-t border-gray-200"
        >
          <ais-pagination />
          <ais-hits-per-page
            :items="[
              { label: '10 par page', value: 10, default: true },
              { label: '20 par page', value: 20 }
            ]"
          />
        </div>
      </ais-instant-search>
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';

export default {
  name: 'SearchRelations',
  props: { bid: String },
  computed: {
    ...mapState('search', ['loading']),
    ...mapGetters('search', ['searchClient'])
  },
  created() {
    const bid = this.bid;
    this.tokenGet({ bid });
  },
  methods: {
    ...mapActions('search', ['tokenGet']),
    tsToIso(value, limit) {
      return value && value !== limit
        ? DateTime.fromSeconds(value).toISODate()
        : '';
    },
    isoToTs(value, limit) {
      return value
        ? DateTime.fromISO(value, { zone: 'utc' }).toSeconds()
        : limit;
    }
  },
  metaInfo: {
    title: 'Recherche dans les relations'
  }
};
</script>
